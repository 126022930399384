<template>
    <v-container fluid>
        <ValidationObserver ref="observer" v-slot="{ invalid, validated }">
            <v-toolbar flat>
                <v-toolbar-title v-if="advancedMode">
                    <colored-status :value="statusActive" :tooltip="statusText" />
                    {{ $t('oamjobad/label--page-title') }}
                    -
                    {{ $t('oamcommon/action--view-adv') }} #{{ id }}
                </v-toolbar-title>
                <v-toolbar-title v-if="!advancedMode">
                    <colored-status :value="statusActive" :tooltip="statusText" />
                    {{ $t('oamjobad/label--page-title') }}
                    -
                    {{ $t('oamcommon/action--view-simple') }} #{{ id }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical />
                <a v-if="!advancedMode" href="#" @click="toggleMode()">{{ $t('oamcommon/action--view-adv') }}</a>
                <a v-if="advancedMode" href="#" @click="toggleMode()">{{ $t('oamcommon/action--view-simple') }}</a>
                <v-spacer />
                <v-tooltip v-if="previewLink" bottom>
                    <template #activator="{ on }">
                        <v-btn
                            outlined
                            color="secondary"
                            class="white--text mr-4"
                            x-small
                            fab
                            @click="openPreviewLink(previewLink, 2)"
                            v-on="on"
                        >
                            <v-icon dark> mdi-eye </v-icon>
                        </v-btn>
                    </template>
                    {{ $t('oamcommon/tooltip--preview-simple') }}
                </v-tooltip>
                <v-tooltip v-if="previewLink" bottom>
                    <template #activator="{ on }">
                        <v-btn
                            outlined
                            color="primary"
                            class="white--text"
                            x-small
                            fab
                            too
                            @click="openPreviewLink(previewLink, 1)"
                            v-on="on"
                        >
                            <v-icon dark> mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    {{ $t('oamcommon/tooltip--preview') }}
                </v-tooltip>
                <v-btn color="primary" outlined class="ma-4" @click="close()">
                    {{ $t('oamjobad/action--close') }}
                </v-btn>
                <v-btn color="primary" :disabled="invalid || !validated" @click="save()">
                    {{ $t('oamjobad/action--save') }}
                </v-btn>
            </v-toolbar>
            <v-card outlined>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row class="text-overline">
                                <v-col class="py-0 px-md-3 mt-2">
                                    {{ $t('oamjobad/label--jobad-hint') }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="$t('oamjobad/label--title')"
                                        rules="required|max:100"
                                    >
                                        <v-text-field
                                            v-model="title"
                                            :label="$t('oamjobad/label--title')"
                                            :counter="100"
                                            :error-messages="errors"
                                            required
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
                                        <template #activator="{ on, attrs }">
                                            <v-text-field
                                                :value="computedDate"
                                                :label="$t('oamjobad/label--published-at')"
                                                clearable
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                @click:clear="publishedAt = null"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="publishedAt"
                                            no-title
                                            color="primary"
                                            @input="dateMenu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        v-model="language"
                                        :items="languageSelect"
                                        :label="$t('oamjobad/label--language')"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        v-model="isYouSalutation"
                                        :items="salutationSelect"
                                        item-text="text"
                                        item-value="value"
                                        :label="$t('oamjobad/label--salutation')"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card flat>
                                <v-card-text class="pa-0 pa-md-2">
                                    <v-row class="text-overline">
                                        <v-col class="py-0 px-md-4">
                                            {{ $t('oamjobad/label--pubs-hint') }}
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <v-col>
                                            <v-radio-group v-model="typeId" class="ma-0">
                                                <v-row no-gutters class="align-center">
                                                    <v-col cols="1">
                                                        <v-radio :value="10"></v-radio>
                                                    </v-col>
                                                    <v-col v-if="!htmlDocId" cols="3">
                                                        <ValidationProvider
                                                            v-slot="{ errors }"
                                                            :rules="typeId == 10 ? 'required' : ''"
                                                            :name="$t('oamjobad/label--htmldoc')"
                                                        >
                                                            <v-text-field
                                                                v-model="computedHtmlDoc"
                                                                class="mb-1"
                                                                readonly
                                                                :label="$t('oamjobad/label--htmldoc')"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col v-if="htmlDocId" cols="3">
                                                        {{ $t('oamjobad/label--htmldoc') }}
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <v-row no-gutters>
                                                            <v-col
                                                                v-if="!htmlDocId"
                                                                cols="12"
                                                                class="d-flex justify-end"
                                                            >
                                                                <v-btn outlined color="primary" @click="showDialog()">
                                                                    {{ $t('oamcommon/label--create') }}
                                                                </v-btn>
                                                            </v-col>

                                                            <v-col
                                                                v-if="htmlDocId"
                                                                cols="12"
                                                                class="d-flex justify-end align-center"
                                                            >
                                                                <v-row no-gutters>
                                                                    <v-col cols="12" xl="6" class="d-flex justify-end">
                                                                        <v-checkbox
                                                                            v-model="isTemplate"
                                                                            :label="
                                                                                $t('oamjobad/label--use-as-template')
                                                                            "
                                                                        />
                                                                    </v-col>
                                                                    <v-col
                                                                        cols="12"
                                                                        xl="6"
                                                                        class="d-flex justify-end align-center"
                                                                    >
                                                                        <v-btn
                                                                            outlined
                                                                            class="mr-3"
                                                                            color="primary"
                                                                            @click="showDialog()"
                                                                        >
                                                                            {{ $t('oamcommon/label--replace') }}
                                                                        </v-btn>
                                                                        <v-btn color="primary" @click="openEditor()">
                                                                            {{ $t('oamcommon/label--edit') }}
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>

                                                <v-row no-gutters class="align-center">
                                                    <v-col cols="1">
                                                        <v-radio :value="20"></v-radio>
                                                    </v-col>
                                                    <v-col cols="11">
                                                        <ValidationProvider
                                                            v-slot="{ errors, valid }"
                                                            :rules="
                                                                typeId == 20
                                                                    ? 'required|mimes:application/pdf|size:10240'
                                                                    : 'mimes:application/pdf|size:10240'
                                                            "
                                                            :name="$t('oamjobad/label--job-ad-file')"
                                                        >
                                                            <v-file-input
                                                                :value="pdfFile"
                                                                class="mb-6"
                                                                :hint="$t('oamjobad/hint--choose-job-ad-file')"
                                                                persistent-hint
                                                                clearable
                                                                show-size
                                                                :label="$t('oamjobad/label--job-ad-file')"
                                                                :error-messages="errors"
                                                                :loading="pdfFileProgress > 0 ? true : false"
                                                                accept=".pdf"
                                                                @click:clear="delPdfFile"
                                                                @change="selectPdfFile"
                                                            >
                                                                <template #append>
                                                                    <v-tooltip
                                                                        v-if="!pdfFileName && pdfFile && valid"
                                                                        bottom
                                                                    >
                                                                        <template #activator="{ on }">
                                                                            <v-icon @click="addPdfFile" v-on="on">
                                                                                mdi-cloud-upload-outline
                                                                            </v-icon>
                                                                        </template>
                                                                        {{ $t('oamjobad/tooltip--upload-file') }}
                                                                    </v-tooltip>
                                                                    <v-tooltip v-if="pdfFileName" bottom>
                                                                        <template #activator="{ on }">
                                                                            <v-icon @click="viewPdfFile" v-on="on">
                                                                                mdi-file-pdf-box-outline
                                                                            </v-icon>
                                                                        </template>
                                                                        {{ $t('oamjobad/tooltip--display-file') }}
                                                                    </v-tooltip>
                                                                </template>
                                                            </v-file-input>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>

                                                <v-row no-gutters class="align-center">
                                                    <v-col cols="1">
                                                        <v-radio :value="30"></v-radio>
                                                    </v-col>
                                                    <v-col cols="11">
                                                        <ValidationProvider
                                                            v-slot="{ errors }"
                                                            :rules="typeId == 30 ? 'required' : ''"
                                                            :name="$t('oamjobad/label--external-jobad-link')"
                                                        >
                                                            <v-text-field
                                                                ref="externalUrl"
                                                                v-model="externalUrl"
                                                                :label="$t('oamjobad/label--external-jobad-link')"
                                                                :error-messages="errors"
                                                                clearable
                                                                @click="selectLink"
                                                            >
                                                                <template #append>
                                                                    <v-tooltip v-if="externalUrl" bottom>
                                                                        <template #activator="{ on }">
                                                                            <v-icon
                                                                                @click="openLink(externalUrl)"
                                                                                v-on="on"
                                                                            >
                                                                                mdi-open-in-new
                                                                            </v-icon>
                                                                        </template>
                                                                        {{ $t('oamcommon/tooltip--open-link') }}
                                                                    </v-tooltip>
                                                                </template>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>

                                                <v-row no-gutters class="mt-5 align-center">
                                                    <v-col cols="1">
                                                        <v-radio :value="0"></v-radio>
                                                    </v-col>
                                                    <v-col cols="11">{{ $t('oamjobad/label--empty-type') }}</v-col>
                                                </v-row>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col v-show="advancedMode" cols="12" md="6">
                            <v-toolbar flat>
                                <v-toolbar-title
                                    >{{ $t('oamjobad/label--jobpublisher') }}
                                    <div class="text-caption">
                                        {{ $t('oamjobad/label--jobpublisher-hint') }}
                                    </div>
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card outlined>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <ValidationProvider
                                                v-slot="{ errors, valid }"
                                                :rules="'ext:jpg,png|size:1024'"
                                                :name="$t('oamjobad/label--publisher-pic')"
                                            >
                                                <v-file-input
                                                    :value="publisherPic"
                                                    :hint="$t('oamjobad/hint--choose-publisher-pic')"
                                                    persistent-hint
                                                    clearable
                                                    show-size
                                                    :label="$t('oamjobad/label--publisher-pic')"
                                                    :error-messages="errors"
                                                    :loading="publisherPicProgress > 0 ? true : false"
                                                    accept=".png,.jpg"
                                                    @click:clear="delPublisherPic"
                                                    @change="selectPublisherPic"
                                                >
                                                    <template #append>
                                                        <v-tooltip
                                                            v-if="!publisherPicName && publisherPic && valid"
                                                            bottom
                                                        >
                                                            <template #activator="{ on }">
                                                                <v-icon @click="addPublisherPic" v-on="on">
                                                                    mdi-cloud-upload-outline
                                                                </v-icon>
                                                            </template>
                                                            {{ $t('oamjobad/tooltip--upload-file') }}
                                                        </v-tooltip>
                                                        <v-tooltip v-if="publisherPicName" bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon @click="viewPublisherPic" v-on="on">
                                                                    mdi-file-image-outline
                                                                </v-icon>
                                                            </template>
                                                            {{ $t('oamjobad/tooltip--display-file') }}
                                                        </v-tooltip>
                                                    </template>
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :rules="'max:250'"
                                                :name="$t('oamjobad/label--teaser-text')"
                                            >
                                                <v-textarea
                                                    v-model="teaserText"
                                                    :error-messages="errors"
                                                    :label="$t('oamjobad/label--teaser-text')"
                                                    counter="250"
                                                    rows="1"
                                                    auto-grow
                                                    clearable
                                                ></v-textarea>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col v-show="advancedMode" cols="12" md="6">
                            <v-toolbar flat>
                                <v-toolbar-title
                                    >{{ $t('oamjobad/label--cvdropper') }}
                                    <div class="caption">
                                        {{ $t('oamjobad/label--cvdropper-hint') }}
                                    </div>
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card outlined>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <ValidationProvider
                                                v-slot="{ errors, valid }"
                                                :rules="'ext:jpg,png|size:2048'"
                                                :name="$t('oamjobad/label--cvdropper-pic')"
                                            >
                                                <v-file-input
                                                    :value="cvDropperPic"
                                                    :hint="$t('oamjobad/hint--choose-cvdropper-pic')"
                                                    persistent-hint
                                                    clearable
                                                    show-size
                                                    :label="$t('oamjobad/label--cvdropper-pic')"
                                                    :error-messages="errors"
                                                    :loading="cvDropperPicProgress > 0 ? true : false"
                                                    accept=".png,.jpg"
                                                    @click:clear="delCvDropperPic"
                                                    @change="selectCvDropperPic"
                                                >
                                                    <template #append>
                                                        <v-tooltip
                                                            v-if="!cvDropperPicName && cvDropperPic && valid"
                                                            bottom
                                                        >
                                                            <template #activator="{ on }">
                                                                <v-icon @click="addCvDropperPic" v-on="on">
                                                                    mdi-cloud-upload-outline
                                                                </v-icon>
                                                            </template>
                                                            {{ $t('oamjobad/tooltip--upload-file') }}
                                                        </v-tooltip>
                                                        <v-tooltip v-if="cvDropperPicName" bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon @click="viewCvDropperPic" v-on="on">
                                                                    mdi-file-image-outline
                                                                </v-icon>
                                                            </template>
                                                            {{ $t('oamjobad/tooltip--display-file') }}
                                                        </v-tooltip>
                                                    </template>
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :rules="'max:400'"
                                                :name="$t('oamjobad/label--cvdropper-text')"
                                            >
                                                <v-textarea
                                                    v-model="cvDropperText"
                                                    :error-messages="errors"
                                                    :label="$t('oamjobad/label--cvdropper-text')"
                                                    :counter="400"
                                                    rows="1"
                                                    auto-grow
                                                    clearable
                                                ></v-textarea>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-toolbar flat class="mt-4">
                <v-toolbar-title>{{ $t('oamjobad/label--table-title') }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical />
                <v-spacer />
                <v-tooltip v-if="typeId != 0" bottom>
                    <template #activator="{ on }">
                        <v-btn
                            class="mb-2"
                            text
                            icon
                            small
                            fab
                            too
                            :disabled="invalid || !validated"
                            @click="addPlatform(1)"
                            v-on="on"
                            ><v-icon dense>mdi-linkedin</v-icon>
                        </v-btn>
                    </template>
                    {{ $t('oampublication/action--new-publication-linkedin') }}
                </v-tooltip>
                <v-tooltip v-if="typeId != 0" bottom>
                    <template #activator="{ on }">
                        <v-btn
                            class="mb-2"
                            text
                            icon
                            small
                            fab
                            too
                            :disabled="invalid || !validated"
                            @click="addPlatform(2)"
                            v-on="on"
                            ><v-icon dense>mdi-xing</v-icon>
                        </v-btn>
                    </template>
                    {{ $t('oampublication/action--new-publication-xing') }}
                </v-tooltip>
                <v-tooltip v-if="typeId != 0" bottom>
                    <template #activator="{ on }">
                        <v-btn
                            class="mb-2"
                            text
                            icon
                            small
                            fab
                            too
                            :disabled="invalid || !validated"
                            @click="addPlatform(3)"
                            v-on="on"
                        >
                            <v-icon dense> mdi-facebook</v-icon>
                        </v-btn>
                    </template>
                    {{ $t('oampublication/action--new-publication-facebook') }}
                </v-tooltip>
                <v-btn color="primary" class="mb-2 ml-4" :disabled="invalid || !validated" @click="add()">
                    {{ $t('oampublication/action--new-publication') }}
                </v-btn>
            </v-toolbar>
            <v-card outlined>
                <v-data-table
                    :headers="headers"
                    :items="pubs"
                    :loading-text="$t('oamcommon/component--loading')"
                    :no-data-text="$t('oampublication/message--no-data')"
                    hide-default-footer
                    disable-pagination
                    dense
                    outlined
                >
                    <template #item="{ item }">
                        <tr>
                            <td class="text-truncate">
                                <colored-status :value="item.statusActive" :tooltip="item.statusText" />
                                {{ item.placeText }}
                            </td>
                            <td class="d-none d-md-table-cell">
                                <span :class="getGreaterThanColor(item.publishedFrom)">
                                    {{ item.publishedFrom | datePresenter }}</span
                                >
                            </td>
                            <td class="d-none d-md-table-cell">
                                <span :class="getLessThanColor(item.publishedTo)">
                                    {{ item.publishedTo | datePresenter }}</span
                                >
                            </td>
                            <td>
                                <v-layout v-if="item.link && item.platformId == 0">
                                    <qr-code :ref="'displayLinkQr_' + item.id" :value="item.link" class="d-none" />
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                class="mr-2"
                                                @click="downloadQrCode('displayLinkQr_' + item.id)"
                                                v-on="on"
                                            >
                                                mdi-qrcode
                                            </v-icon>
                                        </template>
                                        {{ $t('oampublication/tooltip--download-qrcode') }}
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon @click="copyLink(item.link)" v-on="on">
                                                mdi-clipboard-multiple
                                            </v-icon>
                                        </template>
                                        {{ $t('oamcommon/tooltip--copy-link') }}
                                    </v-tooltip>
                                </v-layout>
                            </td>
                            <td>
                                <v-layout v-if="item.applyLink && item.platformId == 0">
                                    <qr-code :ref="'applyLinkQr_' + item.id" :value="item.applyLink" class="d-none" />
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                class="mr-2"
                                                @click="downloadQrCode('applyLinkQr_' + item.id)"
                                                v-on="on"
                                            >
                                                mdi-qrcode
                                            </v-icon>
                                        </template>
                                        {{ $t('oampublication/tooltip--download-qrcode') }}
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon @click="copyLink(item.applyLink)" v-on="on">
                                                mdi-clipboard-multiple
                                            </v-icon>
                                        </template>
                                        {{ $t('oamcommon/tooltip--copy-link') }}
                                    </v-tooltip>
                                </v-layout>
                            </td>
                            <td>
                                <v-switch v-model="item.isActive" dense @change="change(item)"></v-switch>
                            </td>
                            <td>
                                <v-layout>
                                    <v-spacer />
                                    <v-tooltip v-if="item.platformId == 0" bottom>
                                        <template #activator="{ on }">
                                            <v-icon dense class="mr-2" @click="edit(item)" v-on="on">
                                                mdi-pencil
                                            </v-icon>
                                        </template>
                                        {{ $t('oamcommon/tooltip--edit') }}
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon dense @click="del(item)" v-on="on"> mdi-delete</v-icon>
                                        </template>
                                        {{ $t('oamcommon/tooltip--delete') }}
                                    </v-tooltip>
                                </v-layout>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </ValidationObserver>

        <JobAdTemplateSelector
            v-if="showTemplateSelector"
            :dialog="showTemplateSelector"
            :ad="id"
            :first-call="firstCall"
            @show-dialog="showDialog()"
        />

        <Loader :loader="loading" />
    </v-container>
</template>

<script>
import { router } from '@/router'
import { mapActions } from 'vuex'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import moment from 'moment'
import qrCode from 'vue-qrcode'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ColoredStatus from '../../components/ColoredStatus'

export default {
    components: {
        ColoredStatus,
        ValidationProvider,
        ValidationObserver,
        qrCode,
    },
    data: () => ({
        languageSelect: ['DE', 'EN', 'FR', 'IT', 'ES'],
        serverError: null,
        loading: true,
        dateMenu: false,
        showTemplateSelector: false,
        pdfFile: undefined,
        publisherPic: undefined,
        cvDropperPic: undefined,
        pdfFileProgress: 0,
        publisherPicProgress: 0,
        cvDropperPicProgress: 0,
        advancedMode: false,
    }),
    computed: {
        ...mapFields('ad', [
            'currentItem.id',
            'currentItem.statusActive',
            'currentItem.statusText',
            'currentItem.title',
            'currentItem.language',
            'currentItem.isYouSalutation',
            'currentItem.publishedAt',
            'currentItem.teaserText',
            'currentItem.typeId',
            'currentItem.pdfFileName',
            'currentItem.pdfFileSize',
            'currentItem.publisherPicName',
            'currentItem.publisherPicSize',
            'currentItem.cvDropperPicName',
            'currentItem.cvDropperPicSize',
            'currentItem.cvDropperText',
            'currentItem.externalUrl',
            'currentItem.htmlDocId',
            'currentItem.isTemplate',
            'currentItem.previewLink',
        ]),
        ...mapMultiRowFields('pub', ['pubs']),
        adIdFromUrl() {
            return this.$route.params.ad
        },
        computedDate() {
            return this.publishedAt ? moment(this.publishedAt).format('DD.MM.YYYY') : ''
        },
        computedHtmlDoc() {
            return this.htmlDocId ? `${this.$t('oamjobad/label--htmldoc')}` : ''
        },
        headers() {
            return [
                {
                    text: this.$t('oampublication/header--place-text'),
                    value: 'placeText',
                },
                {
                    text: this.$t('oampublication/header--published-from'),
                    value: 'publishedFrom',
                    class: 'd-none d-md-table-cell',
                },
                {
                    text: this.$t('oampublication/header--published-to'),
                    value: 'publishedTo',
                    class: 'd-none d-md-table-cell',
                },
                { text: this.$t('oampublication/header--displayLink'), value: 'link' },
                { text: this.$t('oampublication/header--applyLink'), value: 'applyLink' },
                { text: this.$t('oampublication/header--status'), value: 'isActive' },
                { text: '', value: 'actions', sortable: false },
            ]
        },
        salutationSelect() {
            return [
                { text: this.$t('oamjobad/label--salutation-value1'), value: true },
                { text: this.$t('oamjobad/label--salutation-value2'), value: false },
            ]
        },
        firstCall() {
            return this.htmlDocId ? false : true
        },
    },
    mounted() {
        window.parent.postMessage({ type: 'needConfirmation', value: true }, '*')

        this.getItemById(this.adIdFromUrl).then(() => {
            this.$refs.observer.validate()
            this.getItems(this.adIdFromUrl).then(() => {
                if (this.pdfFileSize && this.pdfFileName) {
                    this.pdfFile = new File(['a'.repeat(this.pdfFileSize)], this.pdfFileName, {
                        type: 'application/pdf',
                    })
                }
                if (this.publisherPicSize && this.publisherPicName) {
                    this.publisherPic = new File(['a'.repeat(this.publisherPicSize)], 'Publisher.png', {
                        type: 'image/*',
                    })
                }
                if (this.cvDropperPicSize && this.cvDropperPicName) {
                    this.cvDropperPic = new File(['a'.repeat(this.cvDropperPicSize)], 'CVdropper.png', {
                        type: 'image/*',
                    })
                }
                this.loading = false
            })
        })
    },
    methods: {
        ...mapActions('ad', ['getItemById', 'editItem', 'clearDoc']),
        ...mapActions('ofs', ['addJobadFile', 'getJobadFile', 'delJobadFile']),
        ...mapActions('pub', ['getItems', 'switchStatus', 'addItem', 'deleteItem']),
        ...mapActions('file', ['addFile', 'getFile', 'delFile']),
        ...mapActions('alert', ['showError', 'showSuccess']),

        close() {
            window.parent.postMessage({ type: 'needConfirmation', value: false }, '*')
            router.push({ name: 'ads' })
        },

        async save() {
            this.loading = true

            if (this.pdfFile && this.pdfFileName != this.pdfFile.name) {
                await this.addPdfFile()
            }

            if (this.publisherPic && this.publisherPicSize != this.publisherPic.size) {
                await this.addPublisherPic()
            }

            if (this.cvDropperPic && this.cvDropperPicSize != this.cvDropperPic.size) {
                await this.addCvDropperPic()
            }

            this.editItem().then(() => {
                window.parent.postMessage({ type: 'needConfirmation', value: false }, '*')
                router.push({ name: 'ads' })
            })
        },

        toggleMode() {
            this.advancedMode = !this.advancedMode
        },

        async simpleSave() {
            const isValid = await this.$refs.observer.validate()
            if (isValid) {
                this.editItem(false)
            }
        },

        edit(pub) {
            router.push({ name: 'pub', params: { pub: pub.id } })
        },

        add() {
            this.simpleSave()
            this.addItem({ id: this.id, platform: 0 }).then((pub) => {
                let id = pub[0].id
                if (id) {
                    router.push({ name: 'pub', params: { pub: id } })
                }
            })
        },

        addPlatform(platform) {
            this.addItem({ id: this.id, platform: platform }).then((pub) => {
                let link = pub[0].platformLink
                if (link) {
                    window.open(link)
                }
                this.getItems(this.$route.params.ad)
            })
        },

        del(pub) {
            this.$confirm(this.$t('oamcommon/message--delete'), {
                title: this.$t('oamcommon/label--warning'),
                buttonTrueText: this.$t('oamcommon/label--yes'),
                buttonFalseText: this.$t('oamcommon/label--no'),
                persistent: true,
            }).then((res) => {
                if (res)
                    this.deleteItem(pub.id).then(() => {
                        this.getItems(this.$route.params.ad)
                    })
            })
        },

        change(pub) {
            this.switchStatus(pub.id).then(() => {
                this.getItems(this.$route.params.ad).then(() => {
                    this.getItemById(this.adIdFromUrl)
                })
            })
        },

        selectLink() {
            this.typeId = 30
        },

        openLink(link) {
            if (link.indexOf('//') > 0) {
                window.open(link)
            } else {
                window.open('//' + link)
            }
        },

        openPreviewLink(link, previewType) {
            this.openLink(link + '?preview=' + previewType)
        },

        openEditor() {
            this.typeId = 10
            this.simpleSave()
            router.push({ name: 'editor', params: { id: this.htmlDocId } })
        },

        showDialog() {
            this.showTemplateSelector = !this.showTemplateSelector
        },

        selectPdfFile(file) {
            this.pdfFileProgress = 0
            this.pdfFile = file
            this.pdfFileName = ''
            this.pdfFileSize = 0
            this.typeId = 20
        },

        selectPublisherPic(file) {
            this.publisherPicProgress = 0
            this.publisherPic = file
            this.publisherPicName = ''
            this.publisherPicSize = 0
        },

        selectCvDropperPic(file) {
            this.cvDropperPicProgress = 0
            this.cvDropperPic = file
            this.cvDropperPicName = ''
            this.cvDropperPicSize = 0
        },

        async addPdfFile() {
            this.pdfFileProgress = 100
            await this.addFile({
                id: this.adIdFromUrl,
                file: this.pdfFile,
                progress: (event) => {
                    this.pdfFileProgress = Math.round((100 * event.loaded) / event.total)
                },
            }).then((err) => {
                if (!err) {
                    this.pdfFileName = this.pdfFile.name
                    this.pdfFileSize = this.pdfFile.size
                } else {
                    this.pdfFile = undefined
                    this.showError(this.$t('oamcommon/message--upload-failed'))
                }
            })
            this.pdfFileProgress = 0
        },

        async addPublisherPic() {
            this.publisherPicProgress = 100
            await this.addJobadFile({
                id: this.adIdFromUrl,
                guid: this.publisherPicName,
                file: this.publisherPic,
                progress: (event) => {
                    this.publisherPicProgress = Math.round((100 * event.loaded) / event.total)
                },
            }).then((data) => {
                if (data) {
                    this.publisherPicName = data.guid
                    this.publisherPicSize = data.file_size
                } else {
                    this.publisherPic = undefined
                    this.showError(this.$t('oamcommon/message--upload-failed'))
                }
            })
            this.publisherPicProgress = 0
        },

        async addCvDropperPic() {
            this.cvDropperPicProgress = 100
            await this.addJobadFile({
                id: this.adIdFromUrl,
                guid: this.cvDropperPicName,
                file: this.cvDropperPic,
                progress: (event) => {
                    this.cvDropperPicProgress = Math.round((100 * event.loaded) / event.total)
                },
            }).then((data) => {
                if (data) {
                    this.cvDropperPicName = data.guid
                    this.cvDropperPicSize = data.file_size
                } else {
                    this.cvDropperPic = undefined
                    this.showError(this.$t('oamcommon/message--upload-failed'))
                }
            })
            this.cvDropperPicProgress = 0
        },

        delPdfFile() {
            this.delFile(this.adIdFromUrl).then(() => {
                this.pdfFileName = null
                this.pdfFileSize = null
            })
        },

        delPublisherPic() {
            this.delJobadFile({ id: this.adIdFromUrl, guid: this.publisherPicName }).then(() => {
                this.publisherPicName = null
                this.publisherPicSize = null
            })
        },

        delCvDropperPic() {
            this.delJobadFile({ id: this.adIdFromUrl, guid: this.cvDropperPicName }).then(() => {
                this.cvDropperPicName = null
                this.cvDropperPicSize = null
            })
        },

        viewPdfFile() {
            this.getFile(this.adIdFromUrl)
                .then((file) => {
                    if (file) {
                        window.open(URL.createObjectURL(file))
                    }
                })
                .catch(() => {
                    this.showError(this.$t('oamcommon/message--open-failed'))
                })
        },

        viewPublisherPic() {
            this.getJobadFile({ id: this.adIdFromUrl, guid: this.publisherPicName })
                .then((file) => {
                    if (file) {
                        window.open(URL.createObjectURL(file))
                    }
                })
                .catch(() => {
                    this.showError(this.$t('oamcommon/message--open-failed'))
                })
        },

        viewCvDropperPic() {
            this.getJobadFile({ id: this.adIdFromUrl, guid: this.cvDropperPicName })
                .then((file) => {
                    if (file) {
                        window.open(URL.createObjectURL(file))
                    }
                })
                .catch(() => {
                    this.showError(this.$t('oamcommon/message--open-failed'))
                })
        },

        copyLink(link) {
            this.$copyText(link)
            this.showSuccess(this.$t('oamcommon/message--link-copied'))
        },

        downloadQrCode(ref) {
            const a = document.createElement('a')
            a.download = ref + '_' + this.id + '.png'
            a.href = this.$refs[ref].dataUrl
            a.dataset.downloadurl = ['image/png', a.download, a.href].join(':')

            const e = document.createEvent('MouseEvents')
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            a.dispatchEvent(e)
        },

        getLessThanColor(date) {
            return new Date(date).getTime() < new Date().getTime() ? 'warning--text' : ''
        },

        getGreaterThanColor(date) {
            return new Date(date).getTime() > new Date().getTime() ? 'warning--text' : ''
        },
    },
}
</script>

<style scoped>
.divider {
    border: none;
}

.v-data-table__wrapper >>> th {
    font-size: 0.9rem;
}
</style>
